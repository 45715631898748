<h2 mat-dialog-title style="text-align: center;">パスワードを送信</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]='form'>
        <p>会社のメールアドレスを入力してください。パスワードを入力したメールアドレスに送信します。</p>
        <mat-form-field class="example-full-width">
            <mat-label><mat-icon>mail</mat-icon> メールアドレス</mat-label>
            <input matInput formControlName="email" placeholder="メールアドレス">
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>キャンセル</button>
    <button class="btnSend" mat-raised-button color="primary" (click)="send()" type="submit">送信</button>
</mat-dialog-actions>
