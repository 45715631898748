import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Response } from '../data';
import * as Globals from '../globals';
// import * as jwt_decode from 'jwt-decode';
import jwt_decode from "jwt-decode";


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }

  getIpAddress(): Observable<any> {
    return this.http.get("https://api.ipify.org?format=json");  
  }

  getIpAddress2(): Observable<{ip: string}> {
    return this.http.get<{ip: string}>(`${Globals.SERVER_URL}/ip-address`)
  }

  login(email: string, password: string, ip: string): Observable<any> {
    let body = {
      email: email,
      password: password,
      ip: ip
    };

    return this.http.post(`${Globals.SERVER_URL}/login`, body)
    .pipe(
      catchError(this.handleError)
    );
  }

  logout(token: string): Observable<Response> {
    let data: any = jwt_decode(token);
    // let data = {sessionId: ''}
    console.log(data);
    let headers = new HttpHeaders({
      Authorization: token
    });

    return this.http.delete<Response>(`${Globals.SERVER_URL}/logout/${data.sessionId}`, {headers})
    .pipe(
      map(res => {
        // localStorage.removeItem('sso_token');
        this.cookieService.delete('sso_token');
        return res;
      }),
      catchError(this.handleError)
    );
  }

  twoStepAuthentication(userId: number, code: string, token: string, ipAddress: string): Observable<Response> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    })
    const body = {
      staffId: userId,
      code: code,
      ip: ipAddress
    };

    return this.http.post<Response>(`${Globals.SERVER_URL}/verify_tfa`, body, {headers})
    .pipe(
      catchError(this.handleError)
    );
  }

  recoverPassword(email: string): Observable<any>  {
    const body = {
      email: email,
    };
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })

    return this.http.post<any>(Globals.SERVER_URL + '/recoverPassword', body, {headers})
    .pipe(
      catchError(this.handleError)
    )
  }

  // Send request to reset two step authentication
  resetTFA(token: string): Observable<any>  {
    const body = {};
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    })

    return this.http.post<any>(Globals.SERVER_URL + '/reset-tfa', body, {headers})
    .pipe(
      catchError(this.handleError)
    )
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return of({data: null, message: errorMessage, success: false});
  }
}
