import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment';

import { LoginService } from "../services/login.service";
import { AuthguardService } from "../services/authguard.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form = new FormGroup({
    email: new FormControl('', [Validators.email]),
    password: new FormControl('', []),
    showPassword: new FormControl(),
    rememberMe: new FormControl()
  })

  tf_authentication: Boolean = false;
  rememberMe: boolean = false;
  staff: any;
  token: string;
  ip: string;
  autoFocusEvent = new Subject();
  isLoading: boolean = false;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private authGuardService: AuthguardService,
    private cookieService: CookieService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.checkLogin();
    this.staff = {'_id': ''};
    if (this.cookieService.get('remember')) {
      this.rememberMe = true;
      this.form.patchValue({rememberMe: true});
      this.form.patchValue({email: this.cookieService.get('email')})
      this.form.patchValue({password: this.cookieService.get('password')})
    }
  }

  checkLogin() {
    this.authGuardService.validateToken().subscribe (
      res => {
        if (res.success) {
          this.router.navigate(['/']);
        } else {
          this.cookieService.set('redirectUrl', "https://test.gm-system.net", { sameSite: 'None', secure: true })
        }
      }
    )
  }

  login() {
    this.isLoading = true;
    let email = this.form.get('email').value;
    let password = this.form.get('password').value;

    this.loginService.getIpAddress2().subscribe(
      (res:{ip: string}) => {
        this.ip = res['ip'];
        this.loginService.login(email, password, this.ip)
        .subscribe(
          (res: any) => {
            if (res.success) {
              this.token = res.data.token;
              this.staff = res.data.user;
              if (res.knownIP) {
                this.onVerified(true);
              } else {
                if(res.data.staffTFA.key && res.data.staffTFA.registered){
                  this.tf_authentication = true;
                  this.autoFocusEvent.next();
                }else{
                  this.onVerified(true);
                }
              }
              this.isLoading = false;
            } else {
              this.isLoading = false;
              console.error('Login unsuccessful:', res.message);
              alert(res.message);
            }
          },
          (error: any) => {
            this.isLoading = false;
            console.error('Error occurred during login:', error);
            alert('Error occurred during login: ' + error);
          }
        );
      },
      (error: any) => {
        this.isLoading = false;
        console.error('Error occurred while fetching IP address:', error);
        alert('Error occurred while fetching IP address: ' + error);
      }
    );
  }

  onRememberMe() {
    setTimeout(() => {
      if (this.form.get('rememberMe').value) { this.rememberMe = true; }
      else { this.rememberMe = false; }
    }, 100);
  }

  clearForm() {
    this.form.reset();
  }

  onVerified(success) {
    if (success) {
      this.cookieService.delete('sso_token');
      this.cookieService.set('sso_token', this.token, { sameSite: 'None', secure: true });
      // this.cookieService.set('access_token', this.token, { domain: '.att-system.net', path: '/'});
      // localStorage.setItem('sso_token', this.token);
      //
      // let newIframe = document.createElement('IFRAME');
      // newIframe.id = 'att-system';
      // // newIframe['crossOrigin'] = 'anonymous'
      // newIframe.style.display = "none";
      // (<HTMLIFrameElement>newIframe).src = 'localhost:4200/#/authenticate?token=' + this.token;
      // document.body.appendChild(newIframe);
      // newIframe.onload = async (e) => {

      // }
      // let imgSrc = document.createElement('img');
      // imgSrc.style.display = "none";
      // imgSrc.src = 'https://test.att-system.net/#/authenticate/universal.gif?token=' + this.token;
      // imgSrc.crossOrigin = 'use-credentials';
      // document.body.appendChild(imgSrc);
      // imgSrc.onload = async (e)=> {
      //   console.log(e)
      // }
      //
      let email = this.form.get('email').value;
      let password = this.form.get('password').value;

      if (this.rememberMe) {
        let expiry = moment().add(7, 'days').toDate();
        this.cookieService.set('email', email, { sameSite: 'None', secure: true });
        this.cookieService.set('password', password, { sameSite: 'None', secure: true });
        this.cookieService.set('remember', 'true', { sameSite: 'None', secure: true });
      } else {
        this.cookieService.delete('email');
        this.cookieService.delete('password');
        this.cookieService.delete('remember');
      }
      this.clearForm();
      this.router.navigate(['/home?action=issue']);
    } else {
      // delete the created session
      this.clearForm();
      this.tf_authentication = false;
      this.loginService.logout(this.token).subscribe(
        res => {
          this.token = '';
          this.router.navigate(['login']);
        }
      );

    }
  }
  openDialog() {
    const dialogRef = this.dialog.open(ForgotComponentDialog);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}

// ====================== Forgot password Component =======================

@Component({
  selector: 'forgot-password-dialog',
  templateUrl: 'forgot.password.html',
  styleUrls: ['./login.component.css']
})
export class ForgotComponentDialog {
  form = new FormGroup({
    email: new FormControl('', [Validators.email])
  })

  constructor (private loginService: LoginService) {}

  send() {
    let email = this.form.get('email').value;

    this.loginService.recoverPassword(email).subscribe(
      res => {
        alert(res.message);
      }
    )
  }
}
