<div class="login-wrapper">
  <div class="container">
    <mat-card>
      <header>
        <h1><img src="assets/gms-logo.png" alt=""></h1>
      </header>

    <form class="example-form" [formGroup]='form' (ngSubmit)="login()">
      <mat-form-field class="example-full-width">
        <mat-label><mat-icon>account_circle</mat-icon> メールアドレス</mat-label> <!--Email  -->
        <input matInput formControlName="email"  placeholder="Ex. user@example.com">
        <mat-error *ngIf="form.get('email').hasError('email')">
          Please enter a valid email address
        </mat-error>
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-label><mat-icon>lock</mat-icon> パスワード</mat-label> <!--Password-->
        <input [type]="form.get('showPassword').value ? 'text' : 'password'" matInput formControlName="password"  placeholder="">
      </mat-form-field>
      <p>
        <mat-checkbox formControlName="showPassword" color="primary">パスワードを表示</mat-checkbox> <!--Show password-->
      </p>
      <button class="btn" mat-raised-button color="primary" type="submit" [disabled]="isLoading">
        <span *ngIf="!isLoading">ログイン</span>
        <mat-spinner class="spinner" *ngIf="isLoading" [diameter]="27"></mat-spinner>
      </button> <!--Sign in-->

      <div class="options">
        <p class="remember-me">
          <mat-checkbox formControlName="rememberMe" color="primary" (click)="onRememberMe()">パスワードを記憶する</mat-checkbox> <!--Remember me-->
        </p>
        <span>パスワードを忘れた方は <a style="cursor: pointer; color: rgb(7, 105, 233);" (click)="openDialog()">コチラ</a></span>

      </div>
    </form>
    </mat-card>
  </div>
</div>

<!-- 2 step authentication  -->
<app-two-factor-authentication
  [class.hide]="!tf_authentication"
  [class.show]="tf_authentication"
  [staffId]="staff._id"
  [token]="token"
  [ip]="ip"
  [autoFocusEvent]="autoFocusEvent"
  (onVerified)="onVerified($event)">
</app-two-factor-authentication>
