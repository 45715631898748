import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AuthguardService } from "./services/authguard.service";
import { LoginService } from './services/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(
    private authGuardService: AuthguardService,
    private router: Router,
    private loginService: LoginService,
    private cookieService: CookieService
    ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isLogin();
  }

  isLogin(): Observable<boolean> | boolean {
    // let token = localStorage.getItem("sso_token");
    let token = this.cookieService.get('sso_token');
    if (!token) {
      console.log('Login System Auth guard. no token');
      this.router.navigate(['/login']); 
      return;
    }
    return this.authGuardService.validateToken().pipe(
      map( res => {
        if (!res.success) {
          this.loginService.logout(token).subscribe(
            res => {
              this.router.navigate(['/login']); 
            }
          );
        }
        return true;
      })
    )
  }


}
