import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { UserSharingService } from '../services/user-sharing.service';
import { TokenService } from "../services/token.service";
import { LoginService } from '../services/login.service';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  redirectUrl = '';
  action = '';
  defaultRedirectUrl = 'https://www.gm-system.net';
  app: string = 'gms';

  constructor(
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private tokenService: TokenService,
    private router: Router,
    private cookieService: CookieService
    ) { }

  ngOnInit(): void {
    // this.redirectUrl = localStorage.getItem('redirectUrl');
    this.redirectUrl = this.cookieService.get('redirectUrl');
    this.action = this.activatedRoute.snapshot.queryParamMap.get("action");

    if (this.action == 'logout') {
      this.logout();
    } else if (this.redirectUrl && this.redirectUrl != '') {
      // localStorage.removeItem('redirectUrl');
      this.cookieService.delete('redirectUrl');
      // window.location.href = this.redirectUrl;
      // localStorage.removeItem('redirectUrl');
      setTimeout(() =>{
        window.location.href = this.redirectUrl;
      }, 3000)
    } else {
      // do nothing...  
    }

  }

  // Use the saved redirect url in the localstorage if didn't receive any url.
  // Or use the gms url incase no url is save in localstorage.
  processRedirectUrl() {
    if (!this.redirectUrl) {
      // let url = localStorage.getItem('redirectUrl');
      let url = this.cookieService.get('redirectUrl');
      if (url) {
        this.redirectUrl = url;
      } else {
        this.redirectUrl = this.defaultRedirectUrl;
      }
    }
  }

  getAppName() {

    if (!this.redirectUrl) { return 'gms'; }

    if (this.redirectUrl.includes('request.gm-system.net')) { return 'apply' }
    else if (this.redirectUrl.includes('omotenashi.gm-system.net')) { return 'omotenashi' }
    else if (this.redirectUrl.includes('att-system.net')) { return 'attendance' }
    else if (this.redirectUrl.includes('mobile.att-system.net')) { return 'attendance-mobile' }
    else if (this.redirectUrl.includes('portfolio.gm-system.net') || this.redirectUrl.includes('54.250.27.248') ) { return 'portfolio' }
    else if (this.redirectUrl.includes('excel-processor-system')) { return 'excel-system' }
    else { return'gms' }
  }

  issueToken() {
    this.loginService.getIpAddress2().subscribe(
      result => {
        let ip = result.ip; 
        this.tokenService.issueToken(ip, this.app).subscribe(
          res => {
            
            if (!res.success) {
              this.logout();
              return;
            }

            this.redirectUrl += '?token=' + res.data.token;
            window.location.href = this.redirectUrl;
          }
        )
      }
    )
  }

  logout() {
    // let token = localStorage.getItem('sso_token');
    let token = this.cookieService.get('sso_token');
    this.loginService.logout(token).subscribe(
      res => {
        this.router.navigate(['/login']); 
      }
    );
  }
}
