import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from "rxjs/operators";

import { Response } from "../data";
import * as Globals from '../globals';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService {
  
  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) { }

  gettoken() {
    // return localStorage.getItem("sso_token");
    return this.cookieService.get('sso_token');
  }

  validateToken(): Observable<Response> {
    // let app = localStorage.getItem('previousRoute');
    let token = this.gettoken();
    let body = {
      token: token
      // app: app
    }

    if (!token) { return of({data: null, message: '', success: false}) }

    return this.http.post<Response>(`${Globals.SERVER_URL}/ssoValidatetoken`, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  // logout(): void{
  //   localStorage.removeItem('sso_token');
  //   this.router.navigate(['/login']);   
  // }

  issueToken(): Observable<Response> {
    let token = this.gettoken();
    let body = {
      token: token
    }

    return this.http.post<Response>(`${Globals.SERVER_URL}/validatetoken`, body)
      .pipe(
        catchError(error => {
          throw error;
        })
      );
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return of({data: null, message: errorMessage, success: false});
  }
}
