import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Router, Event as RouterEvent, NavigationStart } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';

import { TokenService } from './services/token.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  apps = [
    'attendance-mobile',
    'omotenashi',
    'attendance',
    'portfolio',
    'apply',
    'gms'
  ]
  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;
  
  constructor (
    private router: Router,
    private tokenService: TokenService,
    private cookieService: CookieService,
    private ccService: NgcCookieConsentService) { }

    @HostListener('window:message', ['$event'])
    onMessage(e) {
      if (this.apps.includes(e.data)) {
          let app = e.data;
          this.tokenService.requestToken(app).subscribe(
            res => {
              let data = {};

              if (!res.success) {
                data = {
                  token: null,
                  message: 'Logout',
                  success: false
                };
              } else {
                data = {
                  token: res['data'],
                  message: 'Token',
                  success: true
                }
              }
              console.log(data);
              e.source.postMessage(data, e.origin);
            }
          )
      }
    }

  ngOnInit () {
    console.log('login system initializeing...');
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        let url = event['url'];
          if (url.includes('/home?')) {
            // save the redirectUrl so we can redirect you to that URL after login.
            if(url && url.includes('redirectUrl')) {
              let urlArray = decodeURIComponent(url).split('&');

              for(let i = 0; i < urlArray.length; i++) {
                if (urlArray[i].includes('redirectUrl=')) {
                  let redirectUrl = urlArray[i].slice(12);
                  // localStorage.setItem('redirectUrl', redirectUrl);
                  this.cookieService.set('redirectUrl', redirectUrl, { sameSite: 'None', secure: true })
                }
              }
            }
          }
      }
    })
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

}

