<div class="tfa-wrapper">
  <div class="container">
    <h1>2段階認証</h1> <!--2 - step Verification-->
    <h4>確認コードを入力してください</h4> <!--Enter your verfication Code-->

    <form class="tfa-form">
      <mat-form-field class="input">
        <mat-label>コード</mat-label>
        <input matInput 
          placeholder="123456"  
          #inputCode
          name="code" 
          [(ngModel)]="code">
      </mat-form-field>
      <button class="btn" (click)="onVerify()" mat-raised-button color="primary">認証</button> <!--Enter-->
      <button class="btn" (click)="onResetTFA()" mat-raised-button color="primary">Reset TFA</button> <!--Reset TFA-->
    </form>

    <div class="icon-device">
      <span class="material-icons">stay_current_portrait</span>
      <p>二段階認証アプリに表示されているコードを入力してください。</p>
    </div>

    <!-- <div class="download-link">
      <h4>Get it on:</h4>
      <img src="../../../../assets/googleplay.png" (click)="openDialog('android')">
      <img src="../../../../assets/appstore.png" (click)="openDialog('ios')">
    </div> -->
  </div>
</div>