import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
import { catchError } from "rxjs/operators";
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import * as Globals from '../globals';
import { Response } from "../data";

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private http: HttpClient,
    private cookieService: CookieService) { }

  issueToken(ip: string, app: string): Observable<Response> {
    // let token = localStorage.getItem('sso_token');
    let token = this.cookieService.get('sso_token');
    let headers = new HttpHeaders({
      Authorization: token
    })
    let params = new HttpParams()
      .set('ip', ip)
      .set('app', app)
    
    return this.http.get<Response>(`${Globals.SERVER_URL}/issuetoken`, {headers, params})
    .pipe(
      catchError(this.handleError)
    );
  }

  requestToken(app: string): Observable<Response> {
    // let token = localStorage.getItem('sso_token');
    let token = this.cookieService.get('sso_token');
    let headers = new HttpHeaders({
      Authorization: token ? token : ''
    })
    let params = new HttpParams()
    params = params.append('app', app);
    
    return this.http.get<Response>(`${Globals.SERVER_URL}/generate-token`, {headers, params})
      .pipe(
        catchError(this.handleError)
      );
  }

  // Request token for each app. This will return multiple token.
  requestTokens (ip) {
    // let token = localStorage.getItem('sso_token');
    let token = this.cookieService.get('sso_token');
    
    let headers = new HttpHeaders({
      Authorization: token
    })

    return this.http.get<Response>(`${Globals.SERVER_URL}/generatetokens?ip=${ip}`, {headers})
      .pipe(
        catchError(this.handleError)
      );
  }


  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return of({data: null, message: errorMessage, success: false});
  }

}
