import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { LoginService } from "../../services/login.service";

@Component({
  selector: 'app-two-factor-authentication',
  templateUrl: './two-factor-authentication.component.html',
  styleUrls: ['./two-factor-authentication.component.css']
})
export class TwoFactorAuthenticationComponent implements OnInit {
  @Input() staffId: number;
  @Input() token: string;
  @Input() ip: string;
  @Input() autoFocusEvent: Observable<void>;
  @Output() onVerified = new EventEmitter<Boolean>();
  @ViewChild('inputCode') codeField: ElementRef;

  flatForm: string = '';
  code: string = '';

  constructor(
    private loginService: LoginService,
    public dialog: MatDialog,
    ) { }

  ngOnInit(): void {
    // autofocus the code input field
    this.autoFocusEvent.subscribe( () => {
      setTimeout(()=> {
        this.codeField.nativeElement.focus();
      }, 100)
    })
  }

  openDialog(type: string) {
    this.flatForm = type;
    
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: { flatForm: type }
    });

    // dialogRef.afterClosed().subscribe(result => {

    // });
  }

  onVerify() {
    this.loginService.twoStepAuthentication(this.staffId, this.code, this.token, this.ip)
      .subscribe( res => {
        if (res.success) {
          this.onVerified.emit(true);
        } else {
          alert(res.message);
          this.onVerified.emit(false);
        }
        this.code="";
      })
  }

  onResetTFA() {
    this.loginService.resetTFA(this.token).subscribe(
      res => {
        if (res.success) {
          alert('A mail was sent to you email. Please check it.');
          location.reload();
        }
      }
    )
  }

}

@Component({
  selector: 'dialog-overview-example-dialog',
  template: `
    <div class="ui modal basic">
      <div class="header">Google Authenticator ({{data.flatForm}})</div>
      <div class="content">
        <div *ngIf="data.flatForm=='android'"><img src="../../../../assets/google_authenticator_android_qr.png"  alt=""></div>
        <div *ngIf="data.flatForm=='ios'"><img src="../../../../assets/google_authenticator_ios_qr.png" *ngIf="data.flatForm=='ios'" alt=""></div>
      </div>
    </div>
  `,
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}